import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Scope } from '@rocketseat/unform';
import {
  MdAttachMoney,
  MdAutorenew,
  MdCached,
  MdClose,
  MdContentCopy,
  MdDeviceHub,
  MdList,
  MdLock,
  MdStar,
} from 'react-icons/md';
import { RiLoginBoxLine } from 'react-icons/ri';
import { toast } from 'react-toastify';

import { useClearCache } from '../hooks/useClearCache';
import { useLoginAs } from '../hooks/useLoginAs';
import AddressForm from './AddressForm';
import schema from './schema';
import {
  BillingAddress,
  Divider,
  RegistrationToken,
  Tabs,
  UpdatePassword,
} from './styles';
import { usePreviewLoginAs } from '../hooks/usePreviewLoginAs';

import Button from '~/components/Button';
import { Checkbox, Dropdown, Form, Input } from '~/components/Form';
import Page from '~/components/Page';
import api from '~/services/api';
import { useCan } from '~/utils';

function UsersForm({ match, history }) {
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [updatePassword, setUpdatepassword] = useState(false);
  const [user, setUser] = useState({});
  const [billingForeign, setBillingForeign] = useState(false);
  const isUpdating = !!match.params.id;
  const can = useCan();

  const { handle: handleClearCache, isLoading: loadingClearCache } =
    useClearCache();

  const { handle: handleLoginAs, isLoading: loadingLoginAs } = useLoginAs();

  const { handle: handlePreviewLoginAs, isLoading: loadingPreviewLoginAs } =
    usePreviewLoginAs();

  const viewOnly = !can('edit_users');

  function showError(err) {
    let error = err.message;
    if (err.response) {
      const { data, status } = err.response;

      if (Array.isArray(data) && data.length) {
        data.forEach((item) => {
          toast.error(item.message);
        });

        return;
      }

      switch (status) {
        case 400:
          error = data.error.message;
          break;
        case 500:
          error = data;
          break;
        default:
          error = 'Server connection error';
      }
    }
    toast.error(error);
  }

  async function handleSubmit(data) {
    try {
      setSubmitting(true);
      const { id } = match.params;
      await api.postOrPut('/users', id, data);

      toast.success('Usuário salvo com sucesso!');
      history.push('/users');
    } catch (err) {
      showError(err);
      setSubmitting(false);
    }
  }

  async function handleResendEmail(e) {
    e.preventDefault();

    if (resendLoading) return;

    try {
      setResendLoading(true);

      const { id } = match.params;
      await api.post(`/users/${id}/resend-registration-email`);
      toast.success('E-mail foi reenviado ao usuário');
    } catch ({ response }) {
      if (response) {
        const message = response.data.error
          ? response.data.error.message
          : 'Erro interno do servidor';

        toast.error(message);
      }
    } finally {
      setResendLoading(false);
    }
  }

  async function loadTeams() {
    const { data } = await api.get('/teams');

    return data.data;
  }

  async function loadBadges() {
    const { data } = await api.get('/badges', { params: { showAll: true } });

    return data.data;
  }

  useEffect(() => {
    async function loadUser(id) {
      try {
        setLoading(true);

        const { data: response } = await api.get(`/users/${id}`);
        const { billingAddress } = response;

        if (billingAddress) {
          setBillingForeign(billingAddress.foreign_country);
        }

        setUser(response);
      } catch (err) {
        toast.error('Não foi possível');
      } finally {
        setLoading(false);
      }
    }

    const { id } = match.params;
    if (id) {
      loadUser(id);
    }
  }, []);

  return (
    <Page loading={loading}>
      <header>
        {viewOnly ? (
          <h1>Visualizar aluno</h1>
        ) : (
          <h1>{`${isUpdating ? 'Editar' : 'Novo'} aluno`}</h1>
        )}

        <div>
          <Button
            icon={MdCached}
            loading={loadingClearCache}
            disabled={!isUpdating}
            onClick={() => handleClearCache(match.params.id)}
          >
            Limpar Cache
          </Button>

          <a
            href={`https://app.hubspot.com/contacts/21922407/record/0-1/${user.email}`}
            target="_blank"
            rel="noreferrer"
          >
            <Button loading={loading} icon={MdDeviceHub}>
              Hubspot
            </Button>
          </a>

          {can('login-as') && (
            <Button
              icon={RiLoginBoxLine}
              color="default"
              disabled={!isUpdating}
              loading={loadingLoginAs}
              onClick={() => handleLoginAs(match.params.id)}
            >
              Login As
            </Button>
          )}

          {can('login-as') && (
            <Button
              icon={RiLoginBoxLine}
              color="default"
              disabled={!isUpdating}
              loading={loadingPreviewLoginAs}
              onClick={() => handlePreviewLoginAs(match.params.id)}
            >
              Login As (Preview)
            </Button>
          )}

          <Button icon={MdClose} color="cancel" to="/users">
            Cancelar
          </Button>
        </div>
      </header>
      <Tabs>
        <Button
          icon={MdContentCopy}
          color="success"
          to={`/users/journeys/${match.params.id}`}
          disabled={!isUpdating}
        >
          Conteúdos disponíveis
        </Button>

        {can('edit_users-roles') && (
          <Button
            icon={MdLock}
            color="success"
            to={`/users/acl/${match.params.id}`}
            disabled={!isUpdating}
          >
            Grupos e permissões
          </Button>
        )}

        <Button
          icon={MdList}
          color="success"
          to={`/users/log/${match.params.id}`}
          disabled={!isUpdating}
        >
          Histórico de acesso
        </Button>

        <Button
          icon={MdStar}
          color="success"
          to={`/users/pioneer-status/${match.params.id}`}
          disabled={!isUpdating}
        >
          Pioneer Status
        </Button>

        {can('view_purchases') && (
          <Button
            icon={MdAttachMoney}
            color="success"
            to={`/users/purchases/${match.params.id}`}
            disabled={!isUpdating}
          >
            Compras
          </Button>
        )}
      </Tabs>

      <Form
        schema={schema}
        context={{
          updatePassword,
          billingForeign,
        }}
        initialData={user}
        onSubmit={handleSubmit}
      >
        <section>
          <Input
            label="Nome"
            name="name"
            disabled={viewOnly || !can('edit_users-teams')}
            Only={viewOnly}
          />
          <Input label="E-mail" name="email" type="email" readOnly={viewOnly} />

          <RegistrationToken loading={resendLoading}>
            <Input
              label="Token de registro"
              name="registration_token"
              disabled
            />
            {user.registration_token && (
              <a href={window.location} onClick={handleResendEmail}>
                <MdAutorenew size={14} />
                Reenviar e-mail de registro
              </a>
            )}
          </RegistrationToken>
        </section>
        <section>
          <Input label="CPF/CNPJ" name="document" readOnly={viewOnly} />
          <Input label="Celular" name="phone" readOnly={viewOnly} />
        </section>
        <section>
          <Checkbox name="is_verified_phone" label="Telefone verificado" />
          <Checkbox name="pioneer" label="É Pioneer?" disabled={viewOnly} />
        </section>

        <Divider>
          <span>Endereço</span>
        </Divider>

        <BillingAddress show>
          <Scope path="billingAddress">
            <AddressForm
              addressType="billingAddress"
              handleBlurZipcode={() => {}}
              isForeign={billingForeign}
              setForeign={setBillingForeign}
              readOnly={viewOnly}
            />
          </Scope>
        </BillingAddress>
        <Divider>
          <span>Redes sociais</span>
        </Divider>
        <section>
          <Input
            label="Usuário no Discord"
            name="discord"
            disabled={user.discord_id}
            readOnly={viewOnly}
          />
          <Input label="Usuário no Github" name="github" readOnly={viewOnly} />
          <Input
            label="Usuário no Linkedin"
            name="linkedin"
            readOnly={viewOnly}
          />
          <Input
            label="Usuário no Twitter"
            name="twitter"
            readOnly={viewOnly}
          />
        </section>
        {!viewOnly && (
          <>
            {isUpdating && (
              <Divider onClick={() => setUpdatepassword(!updatePassword)}>
                Alterar senha
              </Divider>
            )}
            <UpdatePassword show={updatePassword || !isUpdating}>
              <Input label="Senha" name="password" type="password" />
              <Input
                label="Confirmação da senha"
                name="password_confirmation"
                type="password"
              />
            </UpdatePassword>
          </>
        )}
        <section>
          <Dropdown
            label="Times"
            name="teams"
            multiple
            load={loadTeams}
            disabled={viewOnly || !can('edit_users-teams')}
          />
        </section>

        <section>
          <Dropdown
            label="Insígnias"
            name="badges"
            multiple
            load={loadBadges}
            disabled={viewOnly || !can('edit_users-badges')}
          />
        </section>

        {!viewOnly && (
          <Button type="submit" size="block" loading={submitting}>
            Salvar
          </Button>
        )}
      </Form>
    </Page>
  );
}

UsersForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

UsersForm.defaultProps = {
  match: {
    params: {
      id: '',
    },
  },
};

export default UsersForm;
