import { useQuery, useLazyQuery, useMutation } from '@apollo/client';

import { client } from './client';
import { COUPON, COUPONS, OFFER, OFFERS, PRODUCT, PRODUCTS } from './queries';
import { UPSERT_COUPON, UPSERT_OFFER, UPSERT_PRODUCT } from './mutations';

const defaultOptions = {
  client,
  fetchPolicy: 'no-cache',
};

export const useProducts = (options) => {
  return useQuery(PRODUCTS, { ...defaultOptions, ...options });
};

export const useLazyProducts = (options) => {
  return useLazyQuery(PRODUCTS, { ...defaultOptions, ...options });
};

export const useProduct = (options) => {
  return useQuery(PRODUCT, { ...defaultOptions, ...options });
};

export const useUpsertProductMutation = (options) => {
  return useMutation(UPSERT_PRODUCT, { ...defaultOptions, ...options });
};

export const useOffers = (options) => {
  return useQuery(OFFERS, { ...defaultOptions, ...options });
};

export const useLazyOffers = (options) => {
  return useLazyQuery(OFFERS, { ...defaultOptions, ...options });
};

export const useOffer = (options) => {
  return useQuery(OFFER, { ...defaultOptions, ...options });
};

export const useUpsertOfferMutation = (options) => {
  return useMutation(UPSERT_OFFER, { ...defaultOptions, ...options });
};

export const useCoupons = (options) => {
  return useQuery(COUPONS, { ...defaultOptions, ...options });
};

export const useCoupon = (options) => {
  return useQuery(COUPON, { ...defaultOptions, ...options });
};

export const useUpsertCouponMutation = (options) => {
  return useMutation(UPSERT_COUPON, { ...defaultOptions, ...options });
};
