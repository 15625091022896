/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { MdClose, MdDescription } from 'react-icons/md';
import { toast } from 'react-toastify';

import schema from './schema';

import Button from '~/components/Button';
import {
  Check,
  Checkbox,
  Datepicker,
  Dropdown,
  Form,
  Input,
} from '~/components/Form';
import Page from '~/components/Page';
import api from '~/services/api';
import { getLabel, showError, useCan } from '~/utils';

const types = [
  { id: 'link', title: 'Link' },
  { id: 'correction', title: 'Correção' },
  { id: 'quiz', title: 'Quiz' },
  { id: 'text', title: 'Texto' },
  { id: 'final-challenge', title: 'Desafio Final' },
  { id: 'navigation', title: 'Navigation' },
  { id: 'international-career', title: 'Carreira Internacional' },
];

function ChallengesForm({ match, history }) {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [type, setType] = useState(null);
  const [forms, setForms] = useState(null);
  const [challenge, setChallenge] = useState({});
  const can = useCan();
  const isUpdating = !!match.params.id;

  const viewOnly = !can('edit_challenges');

  async function handleSubmit(data) {
    try {
      setSubmitting(true);

      const { id } = match.params;

      await api.postOrPut('/challenges', id, {
        ...data,
        attempts: data.has_attempts ? data.attempts : null,
      });

      toast.success('Desafio salvo com sucesso!');
      history.push('/challenges');
    } catch ({ response }) {
      showError(response);
    } finally {
      setSubmitting(false);
    }
  }

  function handleOnTypeChange(newType) {
    setType(newType.id);
  }

  async function loadForms() {
    try {
      const { data: response } = await api.get('/forms', {
        params: { showAll: true },
      });

      setForms(response.data);
    } catch ({ response }) {
      showError(response);
    }
  }
  useEffect(() => {
    if (type === 'quiz') {
      loadForms();
    }
  }, [type]);

  async function loadChallenges() {
    const { data: response } = await api.get(`/challenges`, {
      params: {
        showAll: true,
      },
    });

    return response.data;
  }

  useEffect(() => {
    async function loadChallenge(id) {
      try {
        setLoading(true);

        const { data: response } = await api.get(`/challenges/${id}`);

        setChallenge(response);

        if (response.type === 'quiz') {
          await loadForms();
        }

        setType(response.type);
      } catch ({ response }) {
        showError(response);
      } finally {
        setLoading(false);
      }
    }

    const { id } = match.params;
    if (id) {
      loadChallenge(id);
    }
  }, [match.params]);

  return (
    <Page loading={loading}>
      <header>
        {viewOnly ? (
          <h1>Visualizar desafio</h1>
        ) : (
          <h1>{`${isUpdating ? 'Editar' : 'Novo'} desafio`}</h1>
        )}

        <div>
          <Button
            icon={MdDescription}
            color="success"
            to={`/challenges/${match.params.id}/answers`}
            disabled={!isUpdating}
          >
            Respostas
          </Button>

          <Button icon={MdClose} color="cancel" to="/challenges">
            Cancelar
          </Button>
        </div>
      </header>

      <Form schema={schema} initialData={challenge} onSubmit={handleSubmit}>
        <section>
          <Input label="Título" name="title" readOnly={viewOnly} />
          <Input
            label="Slug"
            name="slug"
            placeholder="Slug do desafio (gerado automaticamente)"
            readOnly={viewOnly}
          />
        </section>

        <section>
          <Dropdown
            name="type"
            label="Tipo do desafio"
            options={types}
            onChange={handleOnTypeChange}
          />

          {type === 'quiz' && (
            <Dropdown
              disabled={forms === null}
              name="form_id"
              label="Formulário"
              options={forms || []}
            />
          )}
        </section>

        <section style={{ margin: '0 0 1rem 0', gap: '2.5rem' }}>
          {type === 'quiz' && (
            <Checkbox
              name="has_attempts"
              label={getLabel('Possui quantidade de tentativas')}
              value={challenge?.has_attempts}
              onChange={() =>
                setChallenge({
                  ...challenge,
                  has_attempts: !challenge?.has_attempts,
                })
              }
            />
          )}

          {type === 'quiz' && (
            <Checkbox
              name="show_response"
              label={getLabel('Deve mostrar as respostas ao usuário')}
              value={challenge?.show_response}
              onChange={() =>
                setChallenge({
                  ...challenge,
                  show_response: !challenge?.show_response,
                })
              }
            />
          )}
        </section>

        <section style={{ gap: '2rem' }}>
          {challenge?.has_attempts && type === 'quiz' && (
            <Input
              name="attempts"
              type="number"
              placeholder="Quantidade de tentativas"
            />
          )}

          {type === 'quiz' && (
            <Checkbox
              name="is_evaluative"
              label={getLabel('É um quiz avaliativo')}
              value={challenge?.is_evaluative}
            />
          )}
        </section>

        <section>
          <Input label="Instruções" name="instruction" readOnly={viewOnly} />
          <Input
            label="Resolução"
            name="resolution_url"
            placeholder="Link para resolução do desafio"
            readOnly={viewOnly}
          />
        </section>

        <section>
          <Datepicker
            label="Data de início"
            name="start_at"
            disabled={viewOnly}
          />
          <Datepicker
            label="Data de término"
            name="end_at"
            disabled={viewOnly}
          />
        </section>

        <section>
          <Check
            label="Exigência"
            name="required"
            options={[
              { value: true, label: 'Obrigatório' },
              { value: false, label: 'Opcional' },
            ]}
          />
        </section>

        <section>
          {type === 'quiz' && (
            <Dropdown
              disabled={loading}
              name="blocked_challenge_id"
              label={getLabel(
                'Desafio de bloqueio',
                'Caso o quiz tenha que ser bloqueado por algum outro desafio, selecione o respectivo desafio aqui',
                false,
              )}
              load={loadChallenges}
            />
          )}
        </section>

        <section>
          <Input
            label="Descrição"
            name="description"
            multiline
            readOnly={viewOnly}
          />
        </section>

        {!viewOnly && (
          <Button type="submit" size="block" loading={submitting}>
            Salvar
          </Button>
        )}
      </Form>
    </Page>
  );
}

ChallengesForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

ChallengesForm.defaultProps = {
  match: {
    params: {
      id: '',
    },
  },
};

export default ChallengesForm;
