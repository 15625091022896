import axios from 'axios';
import Cookies from 'universal-cookie';
import { toast } from 'react-toastify';

import {
  setMixpanelIdentifyRequest,
  signOutRequest,
} from '../store/modules/auth/actions';

const cookies = new Cookies();

const api = axios.create({
  baseURL: `${
    process.env.REACT_APP_API_URL || process.env.NEXT_PUBLIC_API_URL
  }/`,
});

/**
 *
 *
 * @param {string} url
 * @param {string=} id
 * @param {string} data
 * @param {Object} [config={}]
 * @returns {Promise<*>}
 */
api.postOrPut = (url, id, data, config = {}) => {
  const method = id ? 'put' : 'post';
  const apiUrl = id ? `${url}/${id}` : url;

  return api[method](apiUrl, data, config);
};

let isRefreshing = false;
let failedQueue = [];

const date = new Date();
const ONE_MONTH = new Date(date.setMonth(date.getMonth() + 1));

/**
 *
 * @function
 * @param {string} error
 * @param {string} [token=null]
 */
const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

/**
 *
 * @function
 * @param {Object} store
 * @param {function} store.dispatch
 */
api.registerInterceptWithStore = (store) => {
  api.interceptors.response.use(
    (response) => response,
    async (err) => {
      const originalRequest = err.config;

      if (!err.response && !originalRequest.supressErrorToast) {
        toast.error('Não foi possível comunicar com o servidor');
      }

      const refreshToken = cookies.get('skylab_next_refresh_token_v3');

      const needsToRefresh =
        err.response &&
        err.response.status === 401 &&
        typeof err.response.data === 'string' &&
        err.response.data.startsWith('E_JWT_TOKEN_EXPIRED');

      if (needsToRefresh) {
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers.Authorization = `Bearer ${token}`;
              return api(originalRequest);
            })
            .catch((error) => error);
        }

        isRefreshing = true;

        return new Promise((resolve, reject) => {
          (async () => {
            try {
              const { data } = await api.post('/sessions/refresh', {
                refresh_token: refreshToken,
              });

              cookies.set('skylab_next_access_token_v3', data.token, {
                path: '/',
                secure: process.env.NODE_ENV !== 'development',
                expires: ONE_MONTH,
              });
              cookies.set('skylab_next_refresh_token_v3', data.refreshToken, {
                path: '/',
                secure: process.env.NODE_ENV !== 'development',
                expires: ONE_MONTH,
              });

              api.defaults.headers.Authorization = `Bearer ${data.token}`;
              originalRequest.headers.Authorization =
                api.defaults.headers.Authorization;

              processQueue(null, data.token);
              resolve(api(originalRequest));
              store.dispatch(setMixpanelIdentifyRequest());
            } catch (error) {
              processQueue(error, null);
              store.dispatch(signOutRequest());
              reject(error);
            } finally {
              isRefreshing = false;
            }
          })();
        });
      }

      return Promise.reject(err);
    },
  );
};

export default api;
