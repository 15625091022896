import React from 'react';

import { Switch } from 'react-router-dom';

import Route from './Route';

import { ChallengesForm, ChallengesList } from '~/pages/content/challenges';
import { NavigationsForm, NavigationsList } from '~/pages/content/navigations';
import {
  ChallengeAnswersForm,
  ChallengeAnswersList,
} from '~/pages/content/challenges/answers';
import { ClustersForm, ClustersList } from '~/pages/content/clusters';
import {
  JourneyForm,
  JourneysList,
  JourneyTeamsExhibition,
} from '~/pages/content/journeys';
import {
  LessonGroupsForm,
  LessonGroupsList,
} from '~/pages/content/lesson-groups';
import { LessonsForm, LessonsList } from '~/pages/content/lessons';
import {
  VideoCategoriesList,
  VideoCategoriesForm,
} from '~/pages/content/video-categories';
import { VideosList, VideosForm } from '~/pages/content/videos';
import {
  LessonSubmissionsList,
  LessonSubmissionsEntries,
} from '~/pages/experts/lesson-submissions';
import { ChangelogsForm, ChangelogsList } from '~/pages/general/changelogs';
import Dashboard from '~/pages/general/Dashboard';
import { ForumsForm, ForumsList } from '~/pages/general/forums';
import Provi from '~/pages/general/Provi';
import { RolesForm, RolesList } from '~/pages/general/Roles';
import {
  SensitiveInformationList,
  SensitiveInformationForm,
} from '~/pages/general/sensitive-information';
import System from '~/pages/general/system';
import { TopBannersList, TopBannersForm } from '~/pages/content/top-banners';
import { TeamsForm, TeamsList } from '~/pages/general/teams';
import {
  UsersForm,
  UsersList,
  UsersPurchasesList,
  UsersPurchasesForm,
  UsersLoginLog,
  UsersEmailHistoryList,
  UsersAcl,
  UsersPioneerStatusList,
  UsersPioneerStatusForm,
} from '~/pages/general/users';
import SignIn from '~/pages/SignIn';
import { FormsForm, FormsList } from '~/pages/content/forms';
import { BadgesForm, BadgesList } from '~/pages/general/badges';
import { ProductsList, ProductsForm } from '~/pages/content/products';
import { OffersForm, OffersList } from '~/pages/content/offers';
import { CouponsForm, CouponsList } from '~/pages/content/coupons';
import { TrialsForm, TrialsList } from '~/pages/general/trials';
import {
  MicroCertificatesForm,
  MicroCertificatesList,
} from '~/pages/content/micro-certificates';
import { TagsForm, TagsList } from '~/pages/content/tags';
import { EventsForm, EventsList } from '~/pages/content/events';
import UsersContentAccessList from '~/pages/general/users/ContentAccess/List';
import { EducatorsForm, EducatorsList } from '~/pages/general/educators';
import {
  CoursesList,
  CoursesForm,
} from '~/pages/content/certificate-service/courses';
import {
  EducatorsList as CertificateEducatorsList,
  EducatorsForm as CertificateEducatorsForm,
} from '~/pages/content/certificate-service/educators';
import {
  CertificateForm,
  CertificatesList,
} from '~/pages/content/certificate-service/certificates';
import { TrailsForm, TrailsList } from '~/pages/content/trails';
import { RemindersForm, RemindersList } from '~/pages/content/reminders';
import { BannersForm, BannersList } from '~/pages/content/banners';
import { CompaniesList } from '~/pages/general/companies';
import CompaniesForm from '~/pages/general/companies/Form';
import { ContractsList } from '~/pages/general/contracts';
import ContractsForm from '~/pages/general/contracts/Form';
import { GoalsForm, GoalsList } from '~/pages/content/guide/goals';
import { LevelsForm, LevelsList } from '~/pages/content/guide/levels';
import { TasksForm, TasksList } from '~/pages/content/guide/tasks';
import UsersAuditsList from '~/pages/content/guide/audits/List';
import { TermsForm, TermsList } from '~/pages/content/terms';
import {
  NotificationsForm,
  NotificationsList,
} from '~/pages/content/notifications/notifications';
import {
  NotificationCategoryGroupsList,
  NotificationCategoryGroupsForm,
} from '~/pages/content/notifications/category-groups';
import {
  NotificationCategoriesList,
  NotificationCategoryForm,
} from '~/pages/content/notifications/categories';
import { RewardsList } from '~/pages/content/rewards';
import RewardsForm from '~/pages/content/rewards/Form';
import { RedeemsList } from '~/pages/content/redeems';
import RedeemsForm from '~/pages/content/redeems/Form';
import { ExperimentsForm, ExperimentsList } from '~/pages/content/experiments';
import {
  CircleWorkspacesForm,
  CircleWorkspacesList,
} from '~/pages/content/circle-workspaces';
import {
  EnrollmentsList,
  EnrollmentsForm,
  EnrollmentsDocumentsForm,
} from '~/pages/content/university/enrollments';

const Routes = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />

    <Route path="/dashboard" component={Dashboard} isPrivate />

    <Route
      exact
      path="/users/pioneer-status/:id/new"
      component={UsersPioneerStatusForm}
      isPrivate
      permission="view_users"
    />

    <Route
      exact
      path="/users/pioneer-status/:id/edit"
      component={UsersPioneerStatusForm}
      isPrivate
      permission="view_users"
    />

    <Route
      exact
      path="/users/pioneer-status/:id"
      component={UsersPioneerStatusList}
      isPrivate
      permission="view_users"
    />

    <Route
      exact
      path="/users/journeys/:id"
      component={UsersContentAccessList}
      isPrivate
      permission="view_users"
    />

    <Route
      exact
      path="/users/acl/:id"
      component={UsersAcl}
      isPrivate
      permission="edit_users-roles"
    />

    <Route
      exact
      path="/users/purchases/:id/new"
      component={UsersPurchasesForm}
      isPrivate
      permission="edit_purchases"
    />
    <Route
      exact
      path="/users/purchases/:id/edit"
      component={UsersPurchasesForm}
      isPrivate
      permission="view_purchases"
    />
    <Route
      exact
      path="/users/purchases/:id"
      component={UsersPurchasesList}
      isPrivate
      permission="view_purchases"
    />

    <Route
      exact
      path="/users/email-history/:id"
      component={UsersEmailHistoryList}
      isPrivate
      permission="view_users"
    />

    <Route
      path="/users/log/:id/:page?"
      component={UsersLoginLog}
      isPrivate
      permission="view_users"
    />

    <Route
      exact
      path="/users/new"
      component={UsersForm}
      isPrivate
      permission="edit_users"
    />
    <Route
      exact
      path="/users/edit/:id"
      component={UsersForm}
      isPrivate
      permission="view_users"
    />
    <Route
      path="/users/:page?"
      component={UsersList}
      isPrivate
      permission="view_users"
    />

    <Route
      exact
      path="/educators/new"
      component={EducatorsForm}
      isPrivate
      permission="edit_users"
    />
    <Route
      exact
      path="/educators/edit/:id"
      component={EducatorsForm}
      isPrivate
      permission="edit_users"
    />
    <Route
      path="/educators/:page?"
      component={EducatorsList}
      isPrivate
      permission="view_users"
    />

    <Route
      exact
      path="/teams/new"
      component={TeamsForm}
      isPrivate
      permission="edit_teams"
    />
    <Route
      exact
      path="/teams/edit/:id"
      component={TeamsForm}
      isPrivate
      permission="view_teams"
    />
    <Route
      path="/teams/:page?"
      component={TeamsList}
      isPrivate
      permission="view_teams"
    />

    <Route
      path="/sensitive-information/edit/:id"
      component={SensitiveInformationForm}
      isPrivate
      permission="view_sensitive-information"
    />
    <Route
      path="/sensitive-information/:page?"
      component={SensitiveInformationList}
      isPrivate
      permission="view_sensitive-information"
    />

    <Route
      exact
      path="/changelogs/new"
      component={ChangelogsForm}
      isPrivate
      permission="edit_changelogs"
    />
    <Route
      exact
      path="/changelogs/edit/:id"
      component={ChangelogsForm}
      isPrivate
      permission="view_changelogs"
    />
    <Route
      path="/changelogs/:page?"
      component={ChangelogsList}
      isPrivate
      permission="view_changelogs"
    />

    <Route
      exact
      path="/journeys/:id/teams-exhibition"
      component={JourneyTeamsExhibition}
      isPrivate
      permission="edit_journeys"
    />
    <Route
      exact
      path="/journeys/new"
      component={JourneyForm}
      isPrivate
      permission="edit_journeys"
    />
    <Route
      exact
      path="/journeys/edit/:id"
      component={JourneyForm}
      isPrivate
      permission="edit_journeys"
    />
    <Route
      exact
      path="/journeys/:page?"
      component={JourneysList}
      isPrivate
      permission="edit_journeys"
    />

    <Route
      path="/lessons/new"
      component={LessonsForm}
      isPrivate
      permission="edit_lessons"
    />
    <Route
      path="/lessons/edit/:id"
      component={LessonsForm}
      isPrivate
      permission="edit_lessons"
    />
    <Route
      path="/lessons/:page?"
      component={LessonsList}
      isPrivate
      permission="edit_lessons"
    />

    <Route
      path="/content-tags/new"
      component={TagsForm}
      isPrivate
      permission="edit_lessons"
    />
    <Route
      path="/content-tags/edit/:id"
      component={TagsForm}
      isPrivate
      permission="edit_lessons"
    />
    <Route
      path="/content-tags/:page?"
      component={TagsList}
      isPrivate
      permission="edit_lessons"
    />

    <Route
      path="/lesson-groups/new"
      component={LessonGroupsForm}
      isPrivate
      permission="edit_lesson-groups"
    />
    <Route
      path="/lesson-groups/edit/:id"
      component={LessonGroupsForm}
      isPrivate
      permission="edit_lesson-groups"
    />
    <Route
      path="/lesson-groups/:page?"
      component={LessonGroupsList}
      isPrivate
      permission="edit_lesson-groups"
    />

    <Route
      path="/clusters/new"
      component={ClustersForm}
      isPrivate
      permission="edit_clusters"
    />
    <Route
      path="/clusters/edit/:id"
      component={ClustersForm}
      isPrivate
      permission="edit_clusters"
    />
    <Route
      path="/clusters/:page?"
      component={ClustersList}
      isPrivate
      permission="edit_clusters"
    />

    <Route
      path="/micro-certificates/new"
      component={MicroCertificatesForm}
      isPrivate
      permission="edit_lessons"
    />
    <Route
      path="/micro-certificates/edit/:id"
      component={MicroCertificatesForm}
      isPrivate
      permission="edit_lessons"
    />
    <Route
      path="/micro-certificates/:page?"
      component={MicroCertificatesList}
      isPrivate
      permission="edit_lessons"
    />

    <Route
      path="/challenges/:challengeId/answers/edit/:id"
      component={ChallengeAnswersForm}
      isPrivate
      permission="view_challenges"
    />
    <Route
      path="/challenges/:challengeId/answers/:page?"
      component={ChallengeAnswersList}
      isPrivate
      permission="view_challenges"
    />

    <Route
      path="/challenges/new"
      component={ChallengesForm}
      isPrivate
      permission="edit_challenges"
    />
    <Route
      path="/challenges/edit/:id"
      component={ChallengesForm}
      isPrivate
      permission="view_challenges"
    />
    <Route
      path="/challenges/:page?"
      component={ChallengesList}
      isPrivate
      permission="view_challenges"
    />

    <Route
      path="/navigations/new"
      component={NavigationsForm}
      isPrivate
      permission="edit_navigations"
    />
    <Route
      path="/navigations/edit/:id"
      component={NavigationsForm}
      isPrivate
      permission="view_navigations"
    />
    <Route
      path="/navigations/:page?"
      component={NavigationsList}
      isPrivate
      permission="view_navigations"
    />

    <Route
      path="/forms/new"
      component={FormsForm}
      isPrivate
      permission="edit_forms"
    />
    <Route
      path="/forms/edit/:id"
      component={FormsForm}
      isPrivate
      permission="edit_forms"
    />
    <Route
      path="/forms/:page?"
      component={FormsList}
      isPrivate
      permission="view_forms"
    />

    <Route
      path="/video-categories/:categoryId/videos/new"
      component={VideosForm}
      isPrivate
      permission="edit_videos"
    />
    <Route
      path="/video-categories/:categoryId/videos/:page?"
      component={VideosList}
      isPrivate
      permission="edit_videos"
    />

    <Route
      path="/video-categories/new"
      component={VideoCategoriesForm}
      isPrivate
      permission="edit_video-categories"
    />
    <Route
      path="/video-categories/edit/:id"
      component={VideoCategoriesForm}
      isPrivate
      permission="edit_video-categories"
    />
    <Route
      path="/video-categories/:page?"
      component={VideoCategoriesList}
      isPrivate
      permission="edit_video-categories"
    />

    <Route
      path="/roles/new"
      component={RolesForm}
      isPrivate
      permission="edit_roles"
    />
    <Route
      path="/roles/edit/:id"
      component={RolesForm}
      isPrivate
      permission="view_roles"
    />
    <Route
      path="/roles/:page?"
      component={RolesList}
      isPrivate
      permission="view_roles"
    />

    <Route
      path="/lesson-submissions/edit/:id"
      component={LessonSubmissionsEntries}
      isPrivate
      permission="review_lesson_submissions"
    />
    <Route
      path="/lesson-submissions/:page?"
      component={LessonSubmissionsList}
      isPrivate
      permission="review_lesson_submissions"
    />

    <Route
      exact
      path="/forums/new"
      component={ForumsForm}
      isPrivate
      permission="edit_forums"
    />
    <Route
      exact
      path="/forums/edit/:id"
      component={ForumsForm}
      isPrivate
      permission="view_forums"
    />
    <Route
      path="/forums/:page?"
      component={ForumsList}
      isPrivate
      permission="view_forums"
    />
    <Route
      path="/provi"
      component={Provi}
      isPrivate
      permission="provi_upload"
    />

    <Route
      path="/badges/new"
      component={BadgesForm}
      isPrivate
      permission="edit_badges"
    />

    <Route
      path="/badges/edit/:id"
      component={BadgesForm}
      isPrivate
      permission="edit_badges"
    />

    <Route
      path="/badges/:page?"
      component={BadgesList}
      isPrivate
      permission="view_badges"
    />

    <Route path="/system" component={System} isPrivate />

    <Route
      exact
      path="/experiments"
      component={ExperimentsList}
      isPrivate
      permission="login_admin"
    />

    <Route
      exact
      path="/experiments/new"
      component={ExperimentsForm}
      isPrivate
      permission="login_admin"
    />

    <Route
      exact
      path="/experiments/edit/:id"
      component={ExperimentsForm}
      isPrivate
      permission="login_admin"
    />

    <Route
      exact
      path="/banners"
      component={BannersList}
      isPrivate
      permission="view_banners"
    />

    <Route
      exact
      path="/banners/new"
      component={BannersForm}
      isPrivate
      permission="edit_banners"
    />

    <Route
      exact
      path="/banners/edit/:id"
      component={BannersForm}
      isPrivate
      permission="edit_banners"
    />

    <Route
      exact
      path="/top-banners"
      component={TopBannersList}
      isPrivate
      permission="view_banners"
    />

    <Route
      exact
      path="/top-banners/new"
      component={TopBannersForm}
      isPrivate
      permission="edit_banners"
    />

    <Route
      exact
      path="/top-banners/edit/:id"
      component={TopBannersForm}
      isPrivate
      permission="edit_banners"
    />

    <Route
      exact
      path="/products"
      component={ProductsList}
      isPrivate
      permission="view_products"
    />
    <Route
      exact
      path="/products/new"
      component={ProductsForm}
      isPrivate
      permission="edit_products"
    />
    <Route
      exact
      path="/products/edit/:identifier"
      component={ProductsForm}
      isPrivate
      permission="edit_products"
    />

    <Route
      exact
      path="/offers"
      component={OffersList}
      isPrivate
      permission="view_offers"
    />
    <Route
      exact
      path="/offers/new"
      component={OffersForm}
      isPrivate
      permission="edit_offers"
    />
    <Route
      exact
      path="/offers/edit/:slug"
      component={OffersForm}
      isPrivate
      permission="edit_offers"
    />

    <Route
      exact
      path="/coupons/new"
      component={CouponsForm}
      isPrivate
      permission="edit_coupons"
    />

    <Route
      exact
      path="/coupons/edit/:code"
      component={CouponsForm}
      isPrivate
      permission="edit_coupons"
    />

    <Route
      path="/coupons/:page?"
      component={CouponsList}
      isPrivate
      permission="view_coupons"
    />

    <Route
      exact
      path="/trials"
      component={TrialsList}
      isPrivate
      permission="view_trials"
    />
    <Route
      exact
      path="/trials/new"
      component={TrialsForm}
      isPrivate
      permission="edit_trials"
    />
    <Route
      exact
      path="/trials/edit/:id"
      component={TrialsForm}
      isPrivate
      permission="edit_trials"
    />

    <Route
      exact
      path="/events/new"
      component={EventsForm}
      isPrivate
      permission="edit_lessons"
    />
    <Route
      exact
      path="/events/edit/:id"
      component={EventsForm}
      isPrivate
      permission="edit_lessons"
    />

    <Route
      path="/events/:page?"
      component={EventsList}
      isPrivate
      permission="edit_lessons"
    />

    <Route
      exact
      isPrivate
      path="/reminders/new"
      permission="login_admin"
      component={RemindersForm}
    />
    <Route
      exact
      isPrivate
      permission="login_admin"
      component={RemindersForm}
      path="/reminders/edit/:id"
    />

    <Route
      isPrivate
      path="/reminders/:page?"
      permission="login_admin"
      component={RemindersList}
    />

    <Route
      exact
      isPrivate
      path="/banners/new"
      permission="login_admin"
      component={BannersForm}
    />
    <Route
      exact
      isPrivate
      permission="login_admin"
      component={BannersForm}
      path="/banners/edit/:id"
    />

    <Route
      isPrivate
      path="/banners/:page?"
      permission="login_admin"
      component={BannersList}
    />

    <Route
      exact
      path="/trails/new"
      component={TrailsForm}
      isPrivate
      permission="edit_lessons"
    />
    <Route
      exact
      path="/trails/edit/:id"
      component={TrailsForm}
      isPrivate
      permission="edit_lessons"
    />

    <Route
      exact
      path="/trails/:page?"
      component={TrailsList}
      isPrivate
      permission="edit_lessons"
    />

    <Route
      exact
      path="/circle/workspaces/new"
      component={CircleWorkspacesForm}
      isPrivate
      permission="edit_lessons"
    />
    <Route
      exact
      path="/circle/workspaces/edit/:id"
      component={CircleWorkspacesForm}
      isPrivate
      permission="edit_lessons"
    />

    <Route
      exact
      path="/circle/workspaces/:page?"
      component={CircleWorkspacesList}
      isPrivate
      permission="edit_lessons"
    />

    <Route
      exact
      path="/certificate-service/courses"
      component={CoursesList}
      isPrivate
      permission="view_certificates"
    />

    <Route
      exact
      path="/certificate-service/course/new"
      component={CoursesForm}
      isPrivate
      permission="create_certificates"
    />

    <Route
      exact
      path="/certificate-service/course/edit/:id"
      component={CoursesForm}
      isPrivate
      permission="edit_certificates"
    />

    <Route
      exact
      path="/certificate-service/educators"
      component={CertificateEducatorsList}
      isPrivate
      permission="view_certificates"
    />

    <Route
      exact
      path="/certificate-service/educator/new"
      component={CertificateEducatorsForm}
      isPrivate
      permission="create_certificates"
    />

    <Route
      exact
      path="/certificate-service/educator/edit/:id"
      component={CertificateEducatorsForm}
      isPrivate
      permission="edit_certificates"
    />

    <Route
      exact
      path="/certificate-service/certificates"
      component={CertificatesList}
      isPrivate
      permission="view_certificates"
    />

    <Route
      exact
      path="/certificate-service/certificate/new"
      component={CertificateForm}
      isPrivate
      permission="create_certificates"
    />

    <Route
      exact
      path="/certificate-service/certificate/edit/:id"
      component={CertificateForm}
      isPrivate
      permission="edit_certificates"
    />

    <Route
      path="/b2b/companies/new"
      exact
      permission="btb:contracts:licenses_create"
      component={CompaniesForm}
      isPrivate
    />

    <Route
      path="/b2b/companies/edit/:id"
      exact
      component={CompaniesForm}
      permission="btb:contracts:licenses_create"
      isPrivate
    />

    <Route
      path="/b2b/companies/:page?"
      permission="btb:contracts_view"
      component={CompaniesList}
      isPrivate
    />

    <Route
      path="/b2b/contracts/new"
      exact
      component={ContractsForm}
      isPrivate
      permission="btb:contracts:licenses_create"
    />

    <Route
      path="/b2b/contracts/edit/:id"
      exact
      component={ContractsForm}
      isPrivate
      permission="btb:contracts:licenses_create"
    />

    <Route
      path="/b2b/contracts/:page?"
      permission="btb:contracts_view"
      component={ContractsList}
      isPrivate
    />

    <Route
      path="/guide/goals/new"
      exact
      permission="create_goals"
      component={GoalsForm}
      isPrivate
    />

    <Route
      path="/guide/goals/edit/:id"
      exact
      component={GoalsForm}
      permission="edit_goals"
      isPrivate
    />

    <Route
      path="/guide/goals/:page?"
      permission="view_goals"
      component={GoalsList}
      isPrivate
    />

    <Route
      path="/guide/levels/new"
      exact
      permission="create_levels"
      component={LevelsForm}
      isPrivate
    />

    <Route
      path="/guide/levels/edit/:id"
      exact
      component={LevelsForm}
      permission="edit_levels"
      isPrivate
    />

    <Route
      path="/guide/levels/:page?"
      permission="view_goals"
      component={LevelsList}
      isPrivate
    />

    <Route
      path="/guide/tasks/new"
      exact
      permission="create_tasks"
      component={TasksForm}
      isPrivate
    />

    <Route
      path="/guide/tasks/edit/:id"
      exact
      component={TasksForm}
      permission="edit_tasks"
      isPrivate
    />

    <Route
      path="/guide/tasks/:page?"
      permission="view_goals"
      component={TasksList}
      isPrivate
      exact
    />

    <Route
      path="/terms/new"
      component={TermsForm}
      isPrivate
      permission="create_terms"
    />

    <Route
      exact
      path="/terms/edit/:id"
      component={TermsForm}
      isPrivate
      permission="edit_terms"
    />

    <Route
      path="/terms/:page?"
      component={TermsList}
      isPrivate
      permission="view_terms"
    />

    <Route
      path="/guide/users/audits/:page?"
      permission="view_goals"
      component={UsersAuditsList}
      isPrivate
    />

    <Route
      exact
      path="/notifications/categories/groups/new"
      component={NotificationCategoryGroupsForm}
      isPrivate
      permission="create_category_groups"
    />

    <Route
      exact
      path="/notifications/categories/groups/edit/:id"
      component={NotificationCategoryGroupsForm}
      isPrivate
      permission="edit_category_groups"
    />

    <Route
      exact
      path="/notifications/categories/groups/:page?"
      component={NotificationCategoryGroupsList}
      isPrivate
      permission="view_category_groups"
    />

    <Route
      exact
      path="/notifications/categories/new"
      component={NotificationCategoryForm}
      isPrivate
      permission="create_categories"
    />

    <Route
      exact
      path="/notifications/categories/edit/:id"
      component={NotificationCategoryForm}
      isPrivate
      permission="edit_categories"
    />

    <Route
      exact
      path="/notifications/categories/:page?"
      component={NotificationCategoriesList}
      isPrivate
      permission="view_categories"
    />

    <Route
      exact
      path="/notifications/new"
      component={NotificationsForm}
      isPrivate
      permission="create_notifications"
    />

    <Route
      exact
      path="/notifications/edit/:id"
      component={NotificationsForm}
      isPrivate
      permission="edit_notifications"
    />

    <Route
      exact
      path="/notifications/:page?"
      component={NotificationsList}
      isPrivate
      permission="view_notifications"
    />

    <Route
      exact
      path="/documents/users/:page?"
      component={EnrollmentsList}
      permission="approve_user_documents"
      isPrivate
    />

    <Route
      exact
      path="/documents/users/:userId/:contractId"
      component={EnrollmentsForm}
      permission="approve_user_documents"
      isPrivate
    />
    <Route
      exact
      path="/documents/users/:userId/:contractId/documents/:documentId?"
      component={EnrollmentsDocumentsForm}
      permission="approve_user_documents"
      isPrivate
    />

    <Route
      exact
      path="/rewards"
      component={RewardsList}
      isPrivate
      permission="view_rewards"
    />

    <Route
      exact
      path="/rewards/edit/:id"
      component={RewardsForm}
      isPrivate
      permission="edit_rewards"
    />

    <Route
      exact
      path="/rewards/new"
      component={RewardsForm}
      isPrivate
      permission="edit_rewards"
    />

    <Route
      exact
      path="/redeems"
      component={RedeemsList}
      isPrivate
      permission="view_redeems"
    />

    <Route
      exact
      path="/redeems/:id"
      component={RedeemsForm}
      isPrivate
      permission="view_redeems"
    />
  </Switch>
);

export default Routes;
