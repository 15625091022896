import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import Button from '~/components/Button';
import { Form, Input, Check, Dropdown, FileInput } from '~/components/Form';
import Page from '~/components/Page';
import { Badge } from '~/pages/content/micro-certificates/Form/styles';
import api from '~/services/api';
import { showError, useCan } from '~/utils';

const schema = Yup.object().shape({
  icon: Yup.string(),
  title: Yup.string().required('Título é um campo obrigatório'),
  type: Yup.string().required('Selecione o tipo'),
  auto_enrollment: Yup.boolean(),
  enroll_everyone: Yup.boolean(),
  journeys: Yup.array().of(Yup.string()),
  identifier: Yup.string(),
  schedule_url: Yup.string(),
});

export const types = [
  {
    id: 'default',
    title: 'Padrão',
  },
  {
    id: 'product',
    title: 'Produto',
  },
  {
    id: 'specialization',
    title: 'Formação',
  },
  {
    id: 'course',
    title: 'Curso',
  },
  {
    id: 'event',
    title: 'Evento',
  },
  {
    id: 'notification',
    title: 'Notificação',
  },
];

function TeamsForm({ match, history }) {
  const [loading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [team, setTeam] = useState({
    auto_enrollment: false,
    enroll_everyone: false,
    chat_id: '69594ce8-785e-4b0c-a267-b66adaa33be6',
    type: 'default',
  });
  const isUpdating = !!match.params.id;
  const can = useCan();
  const { id } = match.params;

  const viewOnly = !can('edit_teams');

  async function handleSubmit(data) {
    try {
      setSubmitting(true);
      await api.postOrPut('/teams', id, data);

      toast.success('Time salvo com sucesso!');
      history.push('/teams');
    } catch ({ response }) {
      showError(response);
    } finally {
      setSubmitting(false);
    }
  }

  async function loadJourneys() {
    const { data } = await api.get('/journeys', {
      params: {
        per_page: 999,
      },
    });
    return data.data;
  }

  useEffect(() => {
    async function loadTeam(teamId) {
      try {
        setLoading(true);

        const { data: response } = await api.get(`/teams/${teamId}`);

        const { auto_enrollment: autoEnrollment } = response;
        setTeam({
          ...response,
          auto_enrollment: autoEnrollment,
          enroll_everyone: false,
        });

        if (response?.type) {
          const typeTitle = types.find(
            (type) => type.id === response.type,
          )?.title;

          setSelectedType({ id: response.type, title: typeTitle });
        }
      } catch ({ response }) {
        showError(response);
      } finally {
        setLoading(false);
      }
    }

    if (id) {
      loadTeam(id);
    }
  }, [id]);

  const handleSelectType = (value) => {
    setSelectedType(value);
  };

  return (
    <Page loading={loading}>
      <header>
        {viewOnly ? (
          <h1>Visualizar time</h1>
        ) : (
          <h1>{`${isUpdating ? 'Editar' : 'Novo'} time`}</h1>
        )}

        <div>
          <Button icon={MdClose} color="cancel" to="/teams">
            Cancelar
          </Button>
        </div>
      </header>

      <Form schema={schema} initialData={team} onSubmit={handleSubmit}>
        <section>
          <Input label="Título" name="title" readOnly={viewOnly} />

          <FileInput
            label="Ícone do time"
            name="icon"
            placeholder="Ícone do time"
            path="platform"
          />
        </section>
        <section>
          <Dropdown
            label="Tipo"
            name="type"
            options={types}
            placeholder="Selecione o tipo"
            disabled={viewOnly}
            onChange={handleSelectType}
          />
        </section>
        <section>
          <Check
            label="Auto inscrição"
            name="auto_enrollment"
            options={[
              { value: true, label: 'Sim' },
              { value: false, label: 'Não' },
            ]}
            readOnly={viewOnly}
          />
          <Check
            label="Inscrever todos usuários"
            name="enroll_everyone"
            options={[
              { value: true, label: 'Sim' },
              { value: false, label: 'Não' },
            ]}
            readOnly={viewOnly}
          />
        </section>

        <section>
          <Dropdown
            label="Jornadas"
            name="journeys"
            multiple
            load={loadJourneys}
            disabled={viewOnly}
            getOptionLabel={(option) => (
              <span>
                {option.title}
                <Badge>{option?.slug}</Badge>
              </span>
            )}
          />
        </section>

        <section>
          <Input label="Identificador" name="identifier" readOnly={viewOnly} />
          {selectedType?.id === 'specialization' && (
            <Input
              label="URL do cronograma"
              name="schedule_url"
              readOnly={viewOnly}
            />
          )}
        </section>

        {!viewOnly && (
          <Button type="submit" size="block" loading={submitting}>
            Salvar
          </Button>
        )}
      </Form>
    </Page>
  );
}

TeamsForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

TeamsForm.defaultProps = {
  match: {
    params: {
      id: '',
    },
  },
};

export default TeamsForm;
