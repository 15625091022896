/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { format } from 'date-fns';

import { useEventForm } from '../use-event-form.hook';

import { Datepicker, Dropdown } from '~/components/HookForm';
import { Alert } from '~/components/ui/alert';
import OfferDateImage from '~/assets/images/tooltips/events/offer-date-field.png';
import OfferImage from '~/assets/images/tooltips/events/offer-field.png';

export function OfferTab() {
  const { event, offers } = useEventForm();
  const { control } = useFormContext();

  const missionCompleteUrl = `http://app.rocketseat.com.br/events/${
    event?.slug || ':slug'
  }/mission-complete`;

  return (
    <div className="flex flex-col gap-2">
      <Alert
        title="Configurações da oferta"
        description={`Essas configurações são responsáveis construir o componente de oferta visível na página de mission complete.

[${missionCompleteUrl}](${missionCompleteUrl}) 
        `}
        className="mb-4"
      />

      <section>
        <Controller
          name="offer"
          control={control}
          render={({ field }) => (
            <Dropdown
              {...field}
              label="Selecione a oferta"
              placeholder="Selecione a oferta deste evento."
              description={`A oferta é exibida ao final do evento na página de mission complete, APENAS PARA QUEM NÃO TEM O ROCKETSEAT ONE, assim como na imagem abaixo:
                <img src="${OfferImage}" className="w-[500px]" />
              `}
              options={offers}
              isClearable
            />
          )}
        />
      </section>

      <section>
        <Datepicker
          label="Oferta começa em"
          name="offers_starts_at"
          description={`Quando a oferta começa, o usuário passa a ser redirecionado para a página de missão completa ao invez da página com as aulas, assim como na imagem abaixo:
            <img src="${OfferDateImage}" className="w-[500px]" />
          `}
          showTimeSelect
          timeIntervals={5}
          placeholder={`${format(new Date(), 'DD/MM/YYYY HH:mm')}`}
        />

        <Datepicker
          label="Oferta termina em"
          name="offers_ends_at"
          description="Quando a oferta acaba, o evento deixa de ser exibido."
          showTimeSelect
          timeIntervals={5}
          placeholder={`${format(new Date(), 'DD/MM/YYYY HH:mm')}`}
        />
      </section>
    </div>
  );
}
