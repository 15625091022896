/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React from 'react';

import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

import {
  ExperimentFormProvider,
  useExperimentForm,
} from '../use-experiment-form.hook';
import { experimentFormSchema } from '../experiment-form.schema';

import { Container } from '~/components/HookForm/Form/styles';
import { Input, Check } from '~/components/HookForm';
import Button from '~/components/Button';
import api from '~/services/api';
import 'react-day-picker/lib/style.css';
import { slugify } from '~/utils';

export function ExperimentsFormComponent({ match, history }) {
  const isUpdating = !!match?.params?.id;
  const { defaultValues } = useExperimentForm();

  const form = useForm({
    defaultValues,
    resolver: yupResolver(experimentFormSchema),
  });

  async function handleSubmit(data) {
    const payload = {
      title: data.title,
      slug: data.slug,
      is_active: !!data.is_active,
      description: data.description,
    };

    try {
      const { id } = match?.params || {};

      await api.postOrPut('/experiments', id, payload);

      toast.success('Experimento salvo com sucesso!');
      history.push('/experiments');
    } catch (err) {
      const message = err.response?.data?.error?.message || 'Algo deu errado.';

      toast.error(message);
    }
  }

  return (
    <>
      <header>
        <h1>{`${isUpdating ? 'Editar' : 'Novo'} Experimento`}</h1>

        <div>
          <Button icon={MdClose} color="cancel" to="/experiments">
            Cancelar
          </Button>
        </div>
      </header>

      <Container>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <section className="flex flex-col">
              <section>
                <Input
                  required
                  name="title"
                  label="Título do experimento"
                  placeholder="Título do experimento"
                />

                <Input
                  required
                  name="slug"
                  label="Slug do experimento"
                  onInput={(e) => {
                    e.target.value = slugify(e.target.value);
                  }}
                  placeholder="slug-do-experimento"
                  description={`
                    Slug é a parte legível a humanos que identifica o experimento de forma única separado por hífens.
                    Exemplo: <strong class="text-cyan-400">slug-do-experimento</strong>

                    Atenção:
                    <strong class="text-red-500">Não é possível usar o mesmo slug em experimentos diferentes.</strong>
                  `}
                />

                <Check
                  required
                  name="is_active"
                  label="Habilitado?"
                  description="Habilita ou desabilita o experimento"
                  options={[
                    { value: true, label: 'Sim' },
                    { value: false, label: 'Não' },
                  ]}
                />
              </section>

              <section>
                <Input
                  name="description"
                  label="Descrição do experimento"
                  placeholder="Descrição sobre o que é o experimento."
                  multiline
                />
              </section>

              <Button
                size="block"
                type="submit"
                loading={form.formState.isSubmitting}
              >
                Salvar
              </Button>
            </section>
          </form>
        </FormProvider>
      </Container>
    </>
  );
}

export default function ExperimentsForm({ match, history }) {
  return (
    <ExperimentFormProvider experimentId={match?.params?.id}>
      <ExperimentsFormComponent match={match} history={history} />
    </ExperimentFormProvider>
  );
}

ExperimentsForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,

  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
